import React from "react"

import TextField from "./TextField"

function PasswordField(props) {
    return (
        <TextField 
            type="password" 
            {...props} />
    );
}

export default PasswordField