import React, { useState, useContext } from "react"
import produce from "immer"

import Card from "../../components/layout/Card"
import AccountPage from "../../components/layout/AccountPage"
import SuccessBox from "../../components/boxes/SuccessBox"
import PasswordField from "../../components/fields/PasswordField"

import { UserContext } from "../../state/UserContext"
import { postData } from "../../services/server"
import { faLock, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import Button from "../../components/buttons/Button"

const ProtectPage = () => {
  const userContext = useContext(UserContext);

  const [formData, setFormData] = useState({
    password: "",
    password2: ""
  });  
  const [error, setError] = useState(null);
  const [busy, setBusy] = useState(false);
  const [validation, setValidation] = useState({})
  const [updated, setUpdated] = useState(false);

  function handleFormChange(event) {
    event.persist();
    setFormData(produce(draft => {
      draft[event.target.name] = event.target.value;
    }));
    setValidation(produce(draft => {
      draft[event.target.name] = "";
      draft["error"] = "";
    }));    
  }

  function onSubmit() {
    const problems = { error: "" }

    if (formData.password.length === 0)
      problems.password = "Required";
    if (formData.password2.length === 0)
      problems.password2 = "Required";
    else if (formData.password != formData.password2)
      problems.password2 = "Passwords don't match.";

    setValidation(problems);

    if (problems.password || problems.password2)
        setError("Please correct the highlighted problems.");
    else {
        setError("");
        setBusy(true);

        postData("/api/users/protect", { 
                email: userContext.email,
                password: formData.password
            },
            (response) => {
                setUpdated(true);
            },
            (error) => {
                console.log(error);
                setError("Error processing request.")
            },
            () => {
                setBusy(false);
            }
        );
    }
  }

  return (
    <AccountPage 
        page="protect" 
        icon={faLock}
        title="Protect Your Designs"
        subtitle="Add a password to protect your email and sign designs."
    >
        {updated ? 
            <SuccessBox>
                Password saved. You need to <strong>confirm</strong> your account
                by clicking the <strong>link</strong> that we have just emailed to you at <strong>{userContext.email}</strong>.
            </SuccessBox>
        :
            <Card>
              <p>
                  {userContext.email}
              </p>
              <PasswordField
                    name="password"
                    label="Password"
                    required
                    value={formData.password}
                    onChange={handleFormChange}
                    wide
                    error={validation.password ? true:false}
                    autoComplete="new-password"
                />
              <PasswordField
                    name="password2"
                    label="Confirm Password"
                    required
                    value={formData.password2}
                    onChange={handleFormChange}
                    wide
                    error={validation.password2 ? true:false}
                />

                <br />

                {(error && error.length > 0) && 
                    <p style={{color:"red"}}>
                        {error}
                    </p>
                }

                <Button
                    color="blue"
                    label="Continue"
                    icon={faChevronRight}
                    size="large"
                    busy={busy}
                    onClick={onSubmit}
                />
            </Card>
        }
      </AccountPage>
  )
}

export default ProtectPage