import React from "react"
import styled from 'styled-components'

const Card = styled.div`
    background-color: white;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    border-radius: 5px;
    
    > div {
        padding: 10px;
    }
`

export default (props) => {
  return (
        <Card style={props.style}>
            <div>
                {props.children}
            </div>
        </Card>
    )
}